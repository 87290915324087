import '../../../../_newcommon/js/events/hv';
import '../../../../_newcommon/js/events/zvuk';

import $ from 'jquery'
import player from 'js/withoutMangle/modules/player/player'
// import kinetic from 'js/modules/kinetic'
// import reloadLazy from 'js/withoutMangle/modules/lazyLoad'
import 'jquery-ui-sortable-npm' // 'jquery-ui-sortable-npm/jquery-ui-sortable.min'
// import init from 'js/withoutMangle/modules/ajaxPagination'
import listen from 'js/withoutMangle/modules/listenAll'
// import 'slick-carousel'
import styles from 'styl/commons/common.styl'

player.init($('.songs'));

require(['js/withoutMangle/modules/search.js']);

let radioClick = function () {
};
require(['js/withoutMangle/modules/radio.js'], function (radio) {
    radioClick = radio;
});
require(['js/withoutMangle/modules/sharedButtons.js']);
require(['js/withoutMangle/modules/mobileMenu.js']);

listen($('.songsListen .item'))

/* auth */
require(['js/withoutMangle/modules/auth.js']);

/* favorite */
require(['js/withoutMangle/modules/favorite.js']);

/* playlist */
require(['js/withoutMangle/modules/playlist.js']);

/* sortable */
require(['js/withoutMangle/modules/sortable.js']);

/* sortableFavorite */
require(['js/withoutMangle/modules/sortableFavorite.js']);

/* search and add in playlist */
require(['js/withoutMangle/modules/search_inPlaylist.js']);


/* btn-ajax */
require(['js/modules/btn_ajax.js']);

/* Suggests */
require(['js/withoutMangle/modules/suggests.js']);

$('.volume-bar').on('click', () => {
    const clear = () => $('.mute').removeClass(['vol50', 'vol0']);
    let mute = $('.mute')
    setTimeout(() => {
        let value = Math.round(100 / $('.volume-bar').width() * $('.volume-bar-value').width());
        if (value > 0 && mute.hasClass('active')) mute.click()
        clear();
        if (value > 30 && value < 70) {
            mute.addClass('vol50')

        } else if (value < 31 && value > 0) {
            mute.addClass('vol0')
        } else if (value === 0) {
            mute.addClass('active')
        }
    }, 150)
});

let songs = $('.songs');

const setDefaultSong = () => {
    const songArtist = $('.songs .item .artist')[0]
    const songName = $('.songs .item .track')[0]
    if (songArtist && songName) {
        $('.player-controls .track-name .track').html(songName.innerText)
        $('.player-controls .track-name .artist').html(songArtist.innerText)
    } else return
}

const nav = $('#favorite_nav');
if (nav.length) {
    const activeItem = $('#favorite_nav .activated_sort');
    let scrollTo = nav.offset().left + activeItem.offset().left - nav.width() / 2 + activeItem.width() / 3;
    nav.scrollLeft(scrollTo);
}

$('.burgerNav_button').on('click', () => {
    $('.burgerNav_icon').toggleClass('active');
    $('#mobileShow').toggleClass('open');
});

$('#main-search').on('click', () => {
    $('.burgerNav_icon').removeClass('active');
    $('#mobileShow').removeClass('open');
});

const menuPopupHandler = (event) => {
    event.stopPropagation();

    const el = event.target;
    $('.popupMenu.open').not($(el)).removeClass('open');
    $(el).toggleClass('open');
    $('.popupMenu_item__share').removeClass('active');
    $('.popupMenu_share').removeClass('open');
};

const popupShareHandler = (event) => {
    $('.popupShare').toggleClass('open');
};

$('button.zvukDlButton, button.zvukPlayButton').on('click', function(event) {
    let code = $(this).data('code');
    if (code) {
        event.stopPropagation();
        event.preventDefault();
        window.open('/zvuk_transfer?q=' + code, '_blank');
    }
});

const closeModal = (event) => {
    let popup = $('.popupMenu');
    if ((!popup.is(event.target) && popup.has(event.target).length === 0)) {
        $('.popupMenu.open').removeClass('open');
    }
    if (!$('.btn_share__popup').is(event.target)) {
        $('.popupShare').removeClass('open');
    }
};

const downloadHandler = (event) => {
    event.stopPropagation();
    $(event.target).children('a')[0].click()
};

songs.on('click', '.popupMenu', menuPopupHandler);
$('.btn_share__popup').on('click', popupShareHandler);
songs.on('click', '.download', downloadHandler);
$(document).mouseup(closeModal);

setDefaultSong()

let playerContainer = $('.player-container');

playerContainer.on('click', '.arr_player', function () {
    let coverLink = $('.songs>.item.active .playlistImg img').attr('src');
    let coverLinkBig = coverLink === '/images/unknown_artist.jpg'
        ? coverLink
        : coverLink.replace(/_.*/, '_large.jpg');
    $('.playerImg img').attr('src', coverLinkBig);

    playerContainer.toggleClass('player-container_default');
    playerContainer.toggleClass('player-container_collapsed');
    $('.player-controls').toggleClass('open');
    $('body').toggleClass('noScroll');
});

playerContainer.on('click', '.prev, .next', function () {
    setTimeout(function() {
        let coverLink = $('.songs>.item.active .playlistImg img').attr('src');
        let coverLinkBig = coverLink === '/images/unknown_artist.jpg'
            ? coverLink
            : coverLink.replace(/_.*/, '_large.jpg');
        $('.playerImg img').attr('src', coverLinkBig);
    }, 5);
});

// if (!app.authorized) {
//     $('.myMusic, .favorite').click(function () {
//         if (window.ya_global && window.ya_global.reachGoal) {
//             window.ya_global.reachGoal('showpopup-auth-click');
//         }
//     });
// }

if (document.cookie.indexOf("user_was_authorized=") != -1) {
    if (window.ya_global && window.ya_global.reachGoal) {
        window.ya_global.reachGoal('auth-users-click');
    }
    document.cookie = "user_was_authorized=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

if (window.app.tmf_url) {

    let freeClick = !~document.cookie.indexOf("teleTwoDownloadFreeClick");

    if (!~document.cookie.indexOf("teleTwoDownload")) {

        let enable = true;

        let dls = document.querySelectorAll('a.' + (styles['dl'] || 'dl'));

        [...dls].forEach(function (dlEl) {
            dlEl.addEventListener('click', function (e) {

                if (freeClick) {
                    freeClick = false;
                    document.cookie = "teleTwoDownloadFreeClick=; path=/;";
                    return;
                }
                if (!enable) return;

                e.preventDefault();

                enable = false;
                document.cookie = "teleTwoDownload=; path=/;";

                window.open(window.app.tmf_url, '_blank').focus();


            });
        })

    }
}

document.addEventListener('DOMContentLoaded', () => {
    const createNotification = (el, message) => {
        console.log(styles['gridPage-tracks'], el)
        const itemEl = el.closest((`.${styles['gridPage-tracks']}`));
        const modalEl = itemEl.querySelector((`.${styles.addedSuccess}, .${styles.addedSuccessCollections}`));

        itemEl.querySelector(`.${styles.inPlaylists}`).classList.remove(styles.check)
        itemEl.querySelector(`.${styles.inPlaylists}`).classList.add(styles.done);
        modalEl.classList.add(styles._now);
        modalEl.innerHTML = `<span>${message}</span>`;
        setTimeout(() => {
            modalEl.classList.remove(styles._now);
            modalEl.innerHTML = ""
        }, 3000);
    }
    const bindRating = () => {
        const ratingEl = document.querySelector(`.${styles.rating}`)
        const ratingBtnEl = document.querySelector(`.${styles.rating__value}`)
        const ratingFormEl = document.querySelector(`.${styles.rating__form}`)

        const sendRating = (form) => {
            const formData = new FormData(form);
            [...form.elements].forEach(item => {
                item.disabled = true
            })
            fetch('/send-rating', {
                method: 'POST',
                body: formData,
                // headers: {
                //     "content-Type": `form/multipart`
                // }
            })
                .then(res => res.json())
                .then(res => {
                    createNotification(form, `Спасибо за оценку`)
                    ratingBtnEl?.removeEventListener('click', btnClickHandler)
                    form.closest(`.${styles.rating}`).classList.remove(styles['is-open'])
                    ratingFormEl?.remove();
                    ratingBtnEl.disabled = true;
                })
                .catch(er => {
                    console.log('error', er)
                })
                .finally(() => {
                    [...form.elements].forEach(item => {
                        item.disabled = false
                    })
                })
        }
        const btnClickHandler = () => {
            const misClickHandler = (e) => {
                const containerEl = e.target.closest(`.${styles.rating}`)
                if (!containerEl) {
                    ratingEl?.classList.remove(styles['is-open'])
                    document.removeEventListener('click', misClickHandler)
                }
            }

            ratingEl.classList.add(styles['is-open'])
            document.addEventListener('click', misClickHandler)
        }
        const changeFormHandler = (e) => {
            sendRating(e.currentTarget)
        }

        ratingBtnEl?.addEventListener('click', btnClickHandler)
        ratingFormEl?.addEventListener('change', changeFormHandler)
    }


    bindRating()
    //Image error handler
    document.querySelectorAll(`img[loading="lazy"]`).forEach(item => {
        item.addEventListener('error', e => {
            e.target.src = e.target.dataset.src || 'error'
        }, {once: true})
        item.src = item.src
    })
})
