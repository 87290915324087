import $ from 'jquery'

var trackList = function (player) {

    var self = this,
        list;
    self.songModel = {};


    this.init = function (songs, cb) {
        list = songs;
        (!!cb && typeof cb == 'function') && cb.call();
    };

    this.bindSongs = function () {
        $(list).on('click', '.item', function (e) {
            if ($(e.target).is('a') || !!$(e.target).parent('a').length) {

                if (!EXCLUDE_ZVOOQ) {
                    if ($(e.target).hasClass('playOtherLink') || $(e.target).hasClass('playOther')) {
                        if (window.ya_global) {
                            ya_global.reachGoal('zvooq' + '.' + ($(e.target).attr('decision') || $(e.target).find('.playOtherLink').attr('decision')));
                        }
                    }
                }

                if ($(e.target).hasClass('download') || $(e.target).hasClass('dl')) {
                    if (window.ya_global) {
                        ya_global.reachGoal('downloadButtonClick' + '.' + ($(e.target).attr('decision') || $(e.target).find('.dl').attr('decision')));
                    }
                }

                return;
            }

            var $self = $(this);
            var playerImg = $('.playerImg img');

            if (app.mobile) {
                $('.player').on('click', '.prev, .next, .arr_player', function () {
                    var oldSrc = $('.playlistImg img', $self).attr('data-src');
                    var newSrc = oldSrc === '/images/unknown_artist.jpg'
                        ? oldSrc.replace(/\.jpg$/i, '_large.jpg')
                        : oldSrc.replace(/_.*/, '_large.jpg')
                    playerImg.attr('src', newSrc);
                });
            }

            if ($('.playlistImg img', $self)[0]) {
                playerImg.attr('src', $('.playlistImg img', $self).attr('data-src'));
                $('.playerImg').show();
                playerImg.css('opacity', '1');
            } else {
                if ($('.collectionTop img')[0]) {
                    playerImg.attr('src', $('.collectionTop img').attr('src'));
                    $('.playerImg').show();
                    playerImg.css('opacity', '1');
                } else {
                    $('.playerImg').hide();
                }
            }

            var target = $(this);

            if (!target.find('.play').attr('data-url')) return;

            target.removeClass('error');
            if (target.hasClass('no_file')) {
                e.preventDefault();
                return false;
            }


            $('.item.load', list).removeClass('load');

            self.bindSongModel($(this), function () {

                if (!!target.find('.play').attr('data-url').length) {

                    if (target.hasClass('pause')) {
                        player.resume();
                        return;
                    }

                    if (target.hasClass('active')) {
                        target.addClass('pause');
                        player.pause();
                    } else {

                        list.find('.active').removeClass('active');
                        list.find('.pause').removeClass('pause');
                        self.songModel.el.addClass('load');
                        player.play();

                        if (window.ya_global && ya_global.reachGoal) {
                            ya_global.reachGoal('playButtonClick');
                        }
                    }

                    return false;

                }/* else {
                        e.preventDefault();

                        // if (!app.token && !self.songModel.fileUrl ) {
                        //     Modal.showNotification('need_auth');
                        //     return false;
                        // }

                        !$(e.target).hasClass('dl') && $(e.target).addClass('load');


                        require(['#common#/../req/requester.js','#common#/../req/request.handler.js'], function(req, reqHandler){

                            var cback = function () {

                                if ($(e.target).hasClass('dl')) {
                                    if (window.ya_global) {
                                        ya_global.reachGoal('downloadButtonClick');
                                    }
                                    $(e.currentTarget).find('a.dl').get(0).click();
                                    !!window.ga && ga('send', 'event', 'download', 'from_tracklist');
                                } else {
                                    $(e.target).click();
                                }

                            };

                            var handler = {};

                            if (!window.app.proxied){

                                handler = new reqHandler(self, cback, self.songModel.songId);

                                req.get(self.songModel.songId, function(resp) {
                                    handler(resp);
                                });

                            } else {

                                handler = new reqHandler(self, cback);

                                req.proxy(function(resp) {
                                    handler(resp);
                                });
                            }

                        });

                    }*/
            });

        });
    };

    this.excludeTrack = function (cb) {
        self.songModel.el.addClass('no_file');
        (!!cb && typeof cb == 'function') && cb.call();
    };
    this.highLightTrack = function () {

        if (self.songModel.el && !self.songModel.el.hasClass('active')) {

            self.songModel.el
                .addClass('active')
                .addClass('played')
                .removeClass('load');

            player.bindPlayerData(self.songModel);
        }
    };

    this.togglePause = function (state) {
        self.songModel.el && self.songModel.el.toggleClass('pause', state);
    };

    this.bindSongModel = function (item, cb) {
        if (!item.is(self.songModel.el)) {
            self.songModel = {
                artist: item.find('.artist').text(),
                track: item.find('.track').text(),
                duration: item.find('.duration').text(),
                likesCount: item.find('.votes.voteLike').text(),
                mp3: item.find('.download a.dl').attr('download'),
                el: item,
                fileUrl: item.find('.play').attr('data-url'),
                downloadUrl: item.find('.download a.dl').attr('href'),
                songId: item.find('.play').attr('data-id'),
                baseId: item.attr('data-baseid'),
                stint: item.attr('stint') !== undefined,
                sourceId: item.parent('ul').attr('data-id'),
                collectionType: item.parent('ul').attr('data-collection-type'),
                collectionId: item.parent('ul').attr('data-collection-id'),
                trackLink: item.find('[data-tracklink]').attr('href'),
                isSend: false,
                img: item.attr('data-img') || ''
            };
        }

        !!cb && typeof cb == 'function' && cb.call();

    };

    this.bindTrackData = function (resp, cb) {
        var data = resp.data;
        $('.play', self.songModel.el)
            .removeAttr('data-id').attr('data-id', ((!!data.owner_id) ? data.owner_id + '_' + data.id : data.id))
            .removeAttr('data-url').attr('data-url', data.url)
            .removeAttr('download').attr('download', data.artist + ' - ' + data.title + '.mp3');

        $('a.dl', self.songModel.el).attr('href', data.url).addClass('ready');

        self.songModel.songId = (!!data.owner_id) ? data.owner_id + '_' + data.id : data.id;
        self.songModel.fileUrl = data.url;

        !!cb && (typeof cb == 'function') && cb.call(this, resp);
    };

    this.playFirst = function () {
        var $list = list;

        if ($list.hasClass('mainSongs')) {
            $list = $list.filter('.mainSongs')
        }

        $('.item', $list).first().click();
        //$('.mobile .songs .item').add('.desktop .songs .item').first().click();
    };

    this.repeatClick = function () {
        self.songModel.el.click();
    };

    this.repeat = function () {
        player.pause();
        player.play(0);
    };

    this.prev = function () {
        (self.songModel.el.prevAll().not('.no_file').first().get(0)) ? self.songModel.el.prevAll().not('.no_file').first().first().click() : self.playFirst();
    };

    this.next = function () {
        ($("~ .item:not(.no_file)", self.songModel.el).first().get(0))
            ? $("~ .item:not(.no_file)", self.songModel.el).first().click()
            : player.pause();
    };

    this.shuffle = function shuffle() {
        var shuffleParrent = self.songModel.el.parent().addClass('shuffle'),
            notPlayed = shuffleParrent.find('.item').not('.played');

        if (notPlayed[0]) {
            var playTarget = $(notPlayed[Math.floor(Math.random() * notPlayed.length)]);
        } else {
            shuffleParrent.find('.item').removeClass('played');
            self.shuffle();
            return;
        }

        playTarget.click();
        $('body, html').animate({ scrollTop: (playTarget.offset().top - 15 - $('.header').height()) }, '800', 'swing');
    };

    return this;
};

export default trackList;
