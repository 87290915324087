import $ from 'jquery'
import MyStatistic from "../../../modules/statistic";

export default function bindControls(player) {

    $('.next', player.controls).on('click', () => {
        bindControls.nextTrack(player);
    });

    $('.prev', player.controls).on('click', () => {
        bindControls.prevTrack(player);
    });

    $('.dl', player.controls).on('click', function (e) {

        if (window.ya_global && window.ya_global.reachGoal) {
            window.ya_global.reachGoal('downloadButtonClick' + '.' + (player.tracklist.songModel.el.find('.dl').attr('decision')));
        }

    });

    $('.play', player.controls).on('click', () => {
        bindControls.play(player);
    });

    $('.play').on('click', () => {
        var item = $('li.item');
        item.parent().removeClass('shuffle');
        item.removeClass('activeFirstLine');
    });

    $('.mute', player.controls).on('click', function (e) {
        $(this).toggleClass('active');
        player.mute($(this).hasClass('active'));
    });

    $('.pause', player.controls).on('click', () => {
        bindControls.pause(player);
    });

    $('.repeat', player.controls).on('click', function (e) {
        $(this).toggleClass('active');
        player.toggleLoop($(this).hasClass('active'));
    });

    $('.shuffle', player.controls).on('click', function (e) {
        $(this).toggleClass('active');
        player.playerModel.shuffle = !player.playerModel.shuffle;
    });
    if (!window.EXCLUDE_ZVOOQ) {
        $('.redirect-message [href]').on('click', function () {
            if (ya_global) {
                ya_global.reachGoal('zvooq');
            }
        })
    }
};

bindControls.nextTrack = (player) => {
    if (player.playerModel.shuffle === true) {
        player.tracklist.shuffle();
    } else {
        if (player.MediaEl.loop === true) {
            player.tracklist.repeat();
        } else {
            if(player.tracklist.songModel.el){
                MyStatistic.playerSkip({
                    timestamp: new Date().getTime(),
                    track_id: +player.tracklist.songModel.songId,
                    collection: player.tracklist.songModel.collectionId ? {
                        id: +player.tracklist.songModel.collectionId,
                        type: player.tracklist.songModel.collectionType,
                    } : null,
                    data: {
                        duration: player.tracklist.songModel.el.data('duration'),
                        listened: +player.MediaEl.getCurrentTime().toFixed()
                    }
                })
            }
            player.tracklist.next();

        }
    }
}

bindControls.prevTrack = (player) => {
    if (player.playerModel.shuffle === true) {
        player.tracklist.shuffle();
    } else {
        if (player.MediaEl.loop === true) {
            player.tracklist.repeat();
        } else {
            player.tracklist.prev();
        }
    }
}

bindControls.pause = (player) => {
    player.pause();
    if (player.plaingRadio) {
        player.plaingRadioEl.removeClass('play').addClass('pause');
    }
}

bindControls.play = (player) => {
    if (player.plaingRadio) {
        player.playRadio(player.plaingRadio, player.plaingRadioEl);
        player.plaingRadioEl.removeClass('pause').addClass('play');
    } else if (!!player.tracklist.songModel.fileUrl) {
        if (player.MediaEl.paused) {
            player.resume();
        } else {
            player.play();

            if (window.ya_global && !!ya_global.reachGoal) {
                ya_global.reachGoal('playButtonClick');
            }

        }
    } else {
        player.tracklist.playFirst();

        if (window.ya_global && !!ya_global.reachGoal) {
            ya_global.reachGoal('playButtonClick');
        }

    }
}
