import $ from 'jquery'
import mediaelementplayer from 'mediaelement/standalone'
import config from './config'
import bindControls from './controls'
import trackList from './tracklist'
import MyStatistic from "../../../modules/statistic";

let app = window.app || {};

var Player = function () {
    var self = this;
    let mediaArtist;
    let mediaTrack;

    window.player = self;

    self.plaingRadio = false;
    self.plaingRadioEl = false;

    self.playerModel = {
        repeat: false,
        shuffle: false,
        init: false
    };

    self.controls = $('.player-controls, .collapsed_player');

    var titlePlace = self.controls,
        downloadBtn = self.controls.find('.dl');

    self.seekbar = $('.progress');
    // self.loaded = $('.progress .load-bar');

    var MediaEl = {};

    this.init = function (songs) {


        self.tracklist = new trackList(self);

        window.tracklist = self.tracklist;

        self.tracklist.init(songs, function () {

            var options = config(self, function (mediaElement) {

                self.MediaEl = mediaElement;

                bindControls(self);
                self.tracklist.bindSongs();

            });

            MediaEl = new MediaElement("player", options);
        });

    };

    this.toggleLoop = function (state) {
        MediaEl.loop = state;
    };

    this.mute = function (state) {
        window.digitalbox && (window.digitalbox.customVolume = state ? "0.1" : MediaEl.getVolume());
        MediaEl.setMuted(state);
    };

    this.toggleState = function (state) {
        self.tracklist.togglePause(!state);
        self.controls.toggleClass('playing', state);
        self.controls.toggleClass('pause', !state);
        $('.listen').toggleClass('playing', state);
        (state) ? self.controls.find('.pause').show() : self.controls.find('.pause').hide();
    };

    this.play = function () {
        self.plaingRadio = false;

        if (!window.tracklist.songModel.fileUrl) {
            window.tracklist.playFirst();
            return;
        }
        MediaEl.pause();
        MediaEl.isInit = true;
        (window.tracklist.songModel.fileUrl) && MediaEl.setSrc([{
            src: window.tracklist.songModel.fileUrl,
            type: 'audio/mp3'
        }]);
        MediaEl.load();
        MediaEl.play();

        $('.arr_player').css('display', 'block');

        (!!self.controls.hasClass('inited') && window.tracklist.songModel.fileUrl) ? self.controls.removeClass('inited') : '';

        self.controls.removeClass('radioControll');
        self.seekbar.removeClass('radioControll');
        if (player.plaingRadioEl) {
            player.plaingRadioEl.removeClass('play').removeClass('load').removeClass('pause');
        }
    };

    this.playRadio = function (href, $elem) {

        self.plaingRadio = href;
        self.plaingRadioEl = $elem;
        self.plaingRadioId = $elem.attr('data-radio_id');

        $elem.addClass('loading');

        MediaEl.pause();
        MediaEl.isInit = true;
        MediaEl.setSrc([{ src: self.plaingRadio, type: 'audio/mp3' }]);
        MediaEl.load();
        MediaEl.play();

        var $title = $('.title', $elem);

        $('.track-name .artist', self.controls).html($title.length ? $title.text() : $elem.attr('data-title'));
        $('.track-name .track', self.controls).html('');


        var playerImg = $('.playerImg img'),
            radioTop = $('.radioTop img');


        if ($('img', $elem)[0]) {
            playerImg.attr('src', $elem.data('img') || $('img', $elem).attr('src'));
            $('.playerImg').show();
            playerImg.css('opacity', '1');
        } else {
            if (radioTop[0]) {
                playerImg.attr('src', radioTop.attr('src'));
                $('.playerImg').show();
                playerImg.css('opacity', '1');
            } else {
                $('.playerImg').hide();
            }
        }

        // (!!self.controls.hasClass('inited') && window.tracklist.songModel.fileUrl) ? self.controls.removeClass('inited') : '';

        self.controls.removeClass('inited');
        self.controls.addClass('radioControll');
        self.seekbar.addClass('radioControll');
        $('.time').css('display', 'none');

        this.showRadioMedia({
            img: $elem.data('img') || $('img', $elem).attr('src') || radioTop.attr('src'),
            title: $title.length ? $title.text() : $elem.attr('data-title')
        })
    };

    this.bindPlayerData = function (songModel) {

        // if (app.rbtAdv && document.location.protocol==='http:' && (document.location.search.indexOf('nr=1')+1)){
        //     if (!$('.group.basic .rbtCont')[0]){
        //         $('.group.basic').append('<div class="rbtCont">div</div>');
        //     }
        //
        //     $('.group.basic .rbtCont').html('');
        //
        //     $('.group.basic .rbtCont').html($('.rbt',songModel.el)[0]);
        // }

        titlePlace.find($('.playerImg')).css('opacity', '1');
        titlePlace.find('.artist').html(songModel.artist);
        titlePlace.find('.track').html(songModel.track);
        titlePlace.find('.duration').html(songModel.duration);
        titlePlace.find('.votes.voteLike').html(songModel.likesCount);
        titlePlace.attr('title', songModel.track + ' — ' + songModel.artist);
        titlePlace.find('.link').attr('href', songModel.trackLink);

        mediaArtist = songModel.artist;
        mediaTrack = songModel.track;

        if (!!downloadBtn) {
            downloadBtn.removeClass('ready')
                .removeAttr('href')
                .attr('href', songModel.downloadUrl)
                .removeAttr('download');
            (!!songModel.mp3) && downloadBtn.attr('download', songModel.mp3);

            downloadBtn.addClass('ready');
        }
        self.showMedia(songModel.img);
    };

    this.resume = function () {
        MediaEl.play();
    };

    this.pause = function () {
        MediaEl.pause();
        window.tracklist.togglePause(true);
    };

    this.stop = function () {
        MediaEl.stop();
    };

    this.showMedia = function (img) {
        if (navigator && ('mediaSession' in navigator)) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: mediaTrack,
                artist: mediaArtist,
                artwork: [
                    { src: img.replace(/small|medium|big/,'large')},
                ]
            });

            navigator.mediaSession.setActionHandler('play', () => {
                bindControls.play(self);
            });
            navigator.mediaSession.setActionHandler('pause', () => {
                bindControls.pause(self);
            });

            navigator.mediaSession.setActionHandler('seekto', (details) => {
                MediaEl.currentTime = details.seekTime;
            });

            if (app.isIos) {
                navigator.mediaSession.setActionHandler('seekforward', function () {
                    MediaEl.currentTime = MediaEl.currentTime + 10;
                });

                navigator.mediaSession.setActionHandler('seekbackward', function () {
                    MediaEl.currentTime = MediaEl.currentTime - 10;
                });
            } else {
                navigator.mediaSession.setActionHandler('previoustrack', () => {
                    bindControls.prevTrack(self);
                });

                navigator.mediaSession.setActionHandler('nexttrack', () => {
                    bindControls.nextTrack(self);
                });
            }
        }
    }

    this.showRadioMedia = function ({img, title}) {
        if (navigator && ('mediaSession' in navigator)) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title,
                artist: `Прямой эфир | ${window.app.application?.toUpperCase()}`,
                artwork: [
                    { src: img?.replace(/small|medium|big/,'large')},
                ]
            });
            navigator.mediaSession.setActionHandler('play', () => {
                bindControls.play(self);
            });
            navigator.mediaSession.setActionHandler('pause', () => {
                bindControls.pause(self);
            });
        }
    }


};

export default new Player;