import $ from 'jquery'
import MyStatistic from "../../../modules/statistic";

function sendEvent(eventObj){
    var xhr = new XMLHttpRequest();
    xhr.open('POST','/utils/tracks-events', true);
    xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    xhr.send(JSON.stringify(eventObj));
}

function bindEvents(player,mediaElement,cb){

    var elemHandler = player.seekbar.find('.handler'),
        elemPlay_bar = player.seekbar.find('.play-bar'),
        currentTime = player.controls.find('.currentTime'),
        timeLeft = player.controls.find('.timeLeft');
    var getVolume = sessionStorage.getItem('volume-value'),
        volumeWidth = getVolume !== null ? getVolume*100+'%' : 90+'%';

    $('.volume-bar-value').width(volumeWidth);

    var events = {
        play : function(e) {
            if (!player.plaingRadio){
                player.tracklist.highLightTrack();
                (player.tracklist.songModel.fileUrl) && !!window.ga && ga('send', 'event', 'player', 'playing');
            }else{
                (!!window.ga && ga('send', 'event', 'player', 'play_radio'));
                if (player.tracklist.songModel.el){
                    player.tracklist.songModel.el.removeClass('load').removeClass('active').removeClass('played');
                }
            }
            player.toggleState(true);

            if (player.plaingRadioEl){
                player.plaingRadioEl.removeClass('loading');
            }


        },
        // progress : function (e) {
        //     // var progress = player.setProgressRail(e,mediaElement);
        //     // console.log(progress);
        // },
        playing: () => {
            MyStatistic.playerPlay({
                timestamp: new Date().getTime(),
                track_id: +player.tracklist.songModel.songId || +player.plaingRadioEl.data('this-radio_id'),
                collection: player.tracklist.songModel.collectionId ? {
                    id: +player.tracklist.songModel.collectionId,
                    type: player.tracklist.songModel.collectionType,
                } : null
            })
        },
        timeupdate :function(e) {
            var percent = 0;
            if(player.tracklist.songModel.stint){
                percent = (mediaElement.currentTime/30)*100+'%';
                elemHandler.attr('style', 'left: ' + percent);
                elemPlay_bar.attr('style', 'width: ' + percent);
                if(mediaElement.currentTime>=30){
                    // $('.messageContainer').addClass('hide');
                    mediaElement.pause();
                    mediaElement.setCurrentTime(0);
                    player.toggleState(false);
                    events.ended();
                    // $('.redirect-message').css('display','flex').css('justify-content', 'center').css('align-items','center').css('height', '59px');
                    // $('.header .container').css('padding','0px');
                    // $('.player-controls').hide();
                    // $('.text-logo').hide();
                }
                return;
            }
            if(!player.tracklist.songModel.isSend && mediaElement.currentTime >= 5 && (!player.plaingRadio || player.plaingRadioId)) {
                player.tracklist.songModel.isSend = true;
                var eventObj = player.plaingRadio ? {sourceId: player.plaingRadioId} : {
                    songId: window.tracklist.songModel.baseId || window.tracklist.songModel.songId,
                    sourceId: window.tracklist.songModel.sourceId
                };
                sendEvent(eventObj);
                if(player.plaingRadio){
                    var intervalId = setInterval(function(radioSrc){
                        if(radioSrc === mediaElement.getSrc() && player.plaingRadio && player.plaingRadioEl.hasClass('play')){
                            sendEvent(eventObj);
                        } else {
                            eventObj = undefined;
                            clearInterval(intervalId);
                        }
                    },3*60*1000/*раз в 3 минуты*/,mediaElement.getSrc())
                }

            }
            percent = (mediaElement.currentTime/mediaElement.duration)*100+'%';
            elemHandler.attr('style', 'left: ' + percent);
            elemPlay_bar.attr('style', 'width: ' + percent);
            var hours = 0;
            var minute = 0;
            var second = 0;
            if(timeLeft){
                hours = Math.floor((mediaElement.duration - mediaElement.currentTime) / (60 * 60));
                minute = Math.floor((mediaElement.duration - mediaElement.currentTime) % (60 * 60) / 60);
                second = Math.floor(mediaElement.duration - mediaElement.currentTime) % 60;
                second = second < 10 ? '0' + second : second||0 + '';
                minute = minute < 10 && hours > 0 ? '0' + minute : minute||0 + '';
                timeLeft.text('-' + (hours ? hours + ':' : '') + (minute||0) + ':' + (second||0));
            }
            if(currentTime){
                hours = Math.floor((mediaElement.currentTime) / (60 * 60));
                minute = Math.floor((mediaElement.currentTime) % (60 * 60) / 60);
                second = Math.floor(mediaElement.currentTime) % 60;
                second = second < 10 ? '0' + second : second||0 + '';
                minute = minute < 10 && hours > 0 ? '0' + minute : minute||0 + '';
                currentTime.text((hours ? hours + ':' : '') + (minute||0) + ':' + (second||0));
            }
        },

        pause: function(e,b){
            player.toggleState(false);
            if(mediaElement.getCurrentTime() < player.tracklist.songModel.el?.data('duration')){
                MyStatistic.playerPause({
                    timestamp: new Date().getTime(),
                    track_id: +player.tracklist.songModel.songId || +player.plaingRadioEl.data('this-radio_id'),
                    collection: player.tracklist.songModel.collectionId ? {
                        id: +player.tracklist.songModel.collectionId,
                        type: player.tracklist.songModel.collectionType,
                    } : null
                })
            }
        },
        canplay: function(){
            getVolume = sessionStorage.getItem('volume-value');

            if (!mediaElement.isInit){
                return false
            }

            mediaElement.setVolume(getVolume !== null ? getVolume : 0.9);
            mediaElement.play();

            window.ya_global && ya_global.reachGoal && ya_global.reachGoal('trackPlaying');

            if(player.tracklist.songModel.stint){
                $('.messageContainer').removeClass('hide');
                $('.messageContainer > .message').text('Трек ограничен по требованию п/о')
            }else{
                $('.messageContainer').addClass('hide');
            }
        },
        loadedmetadata: function () {
            player.tracklist.songModel.isSend = false;
            MyStatistic.playerStart({
                timestamp: new Date().getTime(),
                track_id: +player.tracklist.songModel.songId || +player.plaingRadioEl.data('this-radio_id'),
                collection: player.tracklist.songModel.collectionId ? {
                    id: +player.tracklist.songModel.collectionId,
                    type: player.tracklist.songModel.collectionType,
                } : null
            })
        },
        error: function(e){

            var nextDelay,
                error ='',
                plaingErrorCode = false;

            if (mediaElement.rendererName == 'html5' && mediaElement.error && mediaElement.error.code){

                plaingErrorCode = true;

                switch (mediaElement.error.code) {
                    case 1:
                        error = 'html5: MEDIA_ERR_ABORTED';

                        if (window.ya_global && ya_global.reachGoal) {
                            ya_global.reachGoal('playerError_MEDIA_ERR_ABORTED', {URL: document.location.href });
                        }

                        break;
                    case 2:
                        error = 'html5: MEDIA_ERR_NETWORK';

                        if (window.ya_global && ya_global.reachGoal) {
                            ya_global.reachGoal('playerError_MEDIA_ERR_NETWORK', {URL: document.location.href });
                        }

                        break;
                    case 3:
                        error = 'html5: MEDIA_ERR_DECODE';

                        if (window.ya_global && ya_global.reachGoal) {
                            ya_global.reachGoal('playerError_MEDIA_ERR_DECODE', {URL: document.location.href });
                        }

                        break;
                    case 4:
                        error = 'html5: MEDIA_ERR_SRC_NOT_SUPPORTED';

                        var src = mediaElement.src;
                        var index = src.indexOf('?try=');
                        var count = (index+1) ? parseInt(src.slice(-1))+1 : 1;

                        if(index + 1) src = src.slice(0, index);
                        /*radio*/
                        if (!(player.tracklist.songModel && player.tracklist.songModel.el)) {
                            if (count < 6){
                                setTimeout(function(){
                                    mediaElement.pause();
                                    mediaElement.setSrc([{src: src + '?try=' + count, type:'audio/mp3'}]);
                                    mediaElement.load();
                                },4000);
                                return;
                            }
                            player.plaingRadioEl.removeClass('loading play').addClass('error');
                        }
                        /*track*/
                        if (count<5){
                            var time = mediaElement.currentTime;
                            setTimeout(function(){
                                mediaElement.pause();
                                mediaElement.setSrc([{src: src + '?try=' + count, type:'audio/mp3'}]);
                                mediaElement.load();
                                mediaElement.setCurrentTime(time);
                            },1500);
                            return;
                        }

                        if (window.ya_global && ya_global.reachGoal) {
                            ya_global.reachGoal('playerError_MEDIA_ERR_SRC_NOT_SUPPORTED', {URL: document.location.href });
                        }

                        break;
                }

            } else if (mediaElement.rendererName == 'flash_audio') {

                plaingErrorCode = true;

                switch (e.message){
                    case '2032':
                        error = 'flash: MEDIA_ERR_SRC_NOT_SUPPORTED';
                        break;
                    case '2035':
                        error = 'flash: MEDIA_ERR_NETWORK';
                        break;
                    case '2036':
                        error = 'flash: MEDIA_ERR_ABORTED';
                        break;
                    default:
                        plaingErrorCode = false;
                        error = 'flash: errorCode - ' + e.message;
                }

            } else if (e.message) {
                error = 'e.message: ' + e.message;

                if (window.ya_global && ya_global.reachGoal) {
                    ya_global.reachGoal('playerError_OTHER', {URL: document.location.href });
                }

            } else {
                error = 'unknown error ';

                if (window.ya_global && ya_global.reachGoal) {
                    ya_global.reachGoal('playerError_OTHER', {URL: document.location.href });
                }
            }

            if (plaingErrorCode) {

                error = error + ' userAgent - ' + window.navigator.userAgent + ' mobile: ' + app.mobile.toString();
                !!window.ga && ga('send', 'event', 'player', 'playing_error', error);

                if (!(player.tracklist.songModel && player.tracklist.songModel.el)) {
                    player.plaingRadioEl.removeClass('loading play').addClass('error');
                    if(!player.plaingRadioEl.nextAll("[data-href]").length) return;
                    player.plaingRadioEl.next().click();
                    return;
                }
                player.tracklist.songModel.el.removeClass('played load').addClass('error');

                nextDelay = setTimeout(function(){
                    clearTimeout(nextDelay);
                    player.tracklist.next();
                },1500);

            } else {
                error = error + ' userAgent - ' + window.navigator.userAgent +' mobile: ' + app.mobile.toString()
                    + ' render: ' + ((mediaElement.rendererName) ? mediaElement.rendererName : 'render not init');

                !!window.ga && ga('send', 'event', 'player', 'error' , error);
            }
        },
        ended: function(e) {
            MyStatistic.playerListened({
                timestamp: new Date().getTime(),
                track_id: +player.tracklist.songModel.songId || +player.plaingRadioEl.data('this-radio_id'),
                collection: player.tracklist.songModel.collectionId ? {
                    id: +player.tracklist.songModel.collectionId,
                    type: player.tracklist.songModel.collectionType,
                } : null,
                data: {
                    listened: player.tracklist.songModel.el.data('duration'),
                    duration: player.tracklist.songModel.el.data('duration')
                }
            })
            if (player.playerModel.repeat === true) {
                player.play();
                return;
            }

            if (player.playerModel.shuffle === true) {
                player.tracklist.shuffle();
                return;
            }

            if (!player.plaingRadioEl) {
                if (!!player.tracklist.songModel.el.next().get(0)){
                    player.tracklist.next();
                }else if ($('.' + player.tracklist.songModel.el.parent().data('next_track_list') + ' .item')[0]){
                    $('.' + player.tracklist.songModel.el.parent().data('next_track_list') + ' .item').first().click();
                }else{
                    $('.item',player.tracklist.songModel.el.parent()).first().click();
                }
            }
        }
    };

    for (var event in events) {
        mediaElement.addEventListener(event, events[event]);
    }

    player.controls.find('.volume-bar').on('click',function(e){
        var vol = (e.offsetX / this.offsetWidth);
        player.controls.find('.volume-bar .volume-bar-value').width(vol*100+'%');
        window.digitalbox&&(window.digitalbox.customVolume = vol);
        sessionStorage.setItem('volume-value', vol);
        mediaElement.setVolume(vol);
    });

    player.seekbar.on('click',function(e){
        // readyState (1) - Из медиаресурса получено достаточно, чтобы атрибуты метаданных были инициализированы.
        // readyState (2) - Данные доступны для текущей позиции воспроизведения, но их недостаточно для реального воспроизведения более одного кадра.
        // readyState (3) - Доступны данные для текущей позиции воспроизведения, а также, по меньшей мере, в течение некоторого времени в будущем.
        // readyState (4) - Доступно достаточно данных - и скорость загрузки достаточно высока, - чтобы медиа можно было воспроизвести до конца без перерыва.
        if(mediaElement.readyState >= 1){
            if(player.tracklist.songModel.stint){
                !!mediaElement.currentTime && mediaElement.setCurrentTime((e.originalEvent.offsetX/e.currentTarget.clientWidth)*30);
                return;
            }

            !!mediaElement.currentTime && mediaElement.setCurrentTime((e.originalEvent.offsetX/e.currentTarget.clientWidth)*mediaElement.duration);
        }
    });

    !!cb && typeof cb == 'function' && cb.call(this, mediaElement)
}

export default function(player,cb){

    return {
        renderers: ['html5','flash_audio'],
        enablePluginDebug: false,
        plugins: ['flash'],
        type: '',
        pluginPath: '/',
        flashName: 'mediaelement-flash-audio.swf',
        timerRate: 250,
        startVolume: (window.app.mobile) ? 1 : 0.9,
        loop: false,
        isInit: false,
        autoplay: false,
        success: function (mediaElement, domObject) {
            !!window.ga && ga('send', 'event', 'player', 'ready');
            bindEvents(player,mediaElement,cb);
            // mediaElement.addEventListener('playing', function () {
            //     if(mediaElement.currentTime>30) {mediaElement.pause()}
            //     $('.redirect-message').css('display','none');
            //     $('.player-controls').show();
            //     $('.text-logo').show();
            //     $('.header .container').css('padding-left','14px');
            //
            // })


        },
        // fires when a problem is detected
        error: function (e) {
            !!window.ga && ga('send', 'event', 'player', 'errors_detected' , window.navigator.userAgent);

            if (!!mediaElement.src.match(/\/empty$/)) {
                return;
            }

            !!window.ga && ga('send', 'event', 'player', 'error', 'on mobile - ' + app.mobile.toString());
        }
    };
}